<div *ngIf="fetching" id="overlay">
    <div id="centered-image">
        <img class="loading-img" width="80" height="70" src="assets/images/logo-sm-dark.png" alt="Centered Image">
        <br>
        <br>
        <div style="text-align: center;">
        <div style="margin-left: 15px;" class="loader"></div>
    </div>
    </div>
</div>

<!-- vertical layout -->
<!-- @if(isVerticalLayoutRequested()){
<app-vertical></app-vertical>
} -->

<!-- horizontal layout -->
<!-- @if(isHorizontalLayoutRequested()){ -->
 <div *ngIf="!fetching">
    <!-- <app-vertical></app-vertical> -->
<app-horizontal></app-horizontal>
</div>
<!-- } -->
