<div class="container-fluid">
  <app-pagetitle title="Dashboard"></app-pagetitle>

  <div class="row">
    <div class="col-xl-12">
      <div *ngIf="!fetchingChart" class="card">
        <div class="card-body pb-1">
          <h4 class="card-title">Total Balance</h4>
          <div class="d-flex">
            <h4 style="font-size: 18px; padding-right: 10px;">${{userInvestments.currentBalance | number}}</h4>
            <span *ngIf="amountDifference < 0" class="badge bg-danger-subtle text-danger font-size-11"
              style="height: 20px;"><i class="mdi mdi-menu-down"></i> {{percentageDifference + '% ($' + (amountDifference | number) + ')' }} </span>
            <span *ngIf="amountDifference >= 0" class="badge bg-success-subtle text-success font-size-11"
              style="height: 20px;"><i class="mdi mdi-menu-up"></i> {{percentageDifference + '% ($' + (amountDifference | number) + ')'}} </span>
          </div>
          <div style="height: 300px; margin-top: 20px;">
            <canvas baseChart [data]="lineChartData" type="line" [options]="lineChartOptions">
            </canvas>
          </div>
        </div>

        <!-- <div class="card-body border-top text-center">
          <div class="row">
            <div class="col-sm-4">
              <div class="d-inline-flex">
                <h5 class="me-2">$12,253</h5>
                <div class="text-success">
                  <i class="mdi mdi-menu-up font-size-14"> </i>2.2 %
                </div>
              </div>
              <p class="text-muted text-truncate mb-0">This month</p>
            </div>

            <div class="col-sm-4">
              <div class="mt-4 mt-sm-0">
                <p class="mb-2 text-muted text-truncate"><i class="mdi mdi-circle text-primary font-size-10 me-1"></i>
                  This Year :</p>
                <div class="d-inline-flex">
                  <h5 class="mb-0 me-2">$ 34,254</h5>
                  <div class="text-success">
                    <i class="mdi mdi-menu-up font-size-14"> </i>2.1 %
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="mt-4 mt-sm-0">
                <p class="mb-2 text-muted text-truncate"><i class="mdi mdi-circle text-success font-size-10 me-1"></i>
                  Previous Year :</p>
                <div class="d-inline-flex">
                  <h5 class="mb-0">$ 32,695</h5>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div *ngIf="!fetchingChart" class="row">
        @for(stat of statData;track $index){
        <div class="col-md-4">
          <app-stat [title]="stat.title" [value]="stat.value" [icon]="stat.icon"></app-stat>
        </div>
        }
      </div>
      <!-- end row -->


    </div>


  </div>

  <div class="row">
    <div class="col-xl-4">
      <div class="card">
        <div class="card-body" style="padding-bottom: 6px;">
          <h4 class="card-title">Asset Performance</h4>
          <div style="padding-top: 20px; padding-bottom: 10px; height: 30px; width: 100%; height: 350px;">
            <table style="width: 100%;">
              <tbody>
                <tr>
                  <td style="width: 66.333%; text-align: center;">
                    <div class="d-flex">
                    <div style="border-radius: 50%; background-color: #00000000; width: 35px; 
                          border: 1px solid rgb(134 134 134 / 67%);height: 35px; text-align: center;">
                      <img src="assets/images/logo-sm-dark.png" alt="" height="20px" width="20px"
                        style="margin-top: 7px; ">
                    </div>
                    <p style="font-size: 12px; padding-right: 10px; margin: auto 0; padding: 5px 10px;">Tharwa AI</p>
                  </div>
                  </td>
                  <td style="width: 33.3333%; text-align: end;">
                    <span *ngIf="tharwaAIObject.percentage < 0" class="badge bg-danger-subtle text-danger font-size-11">
                      <i class="mdi mdi-menu-down"></i> {{tharwaAIObject.percentage + '% ($' + (tharwaAIObject.differenceAmount | number) + ')'}} </span>
                    <span *ngIf="tharwaAIObject.percentage >= 0" class="badge bg-success-subtle text-success font-size-11">
                      <i class="mdi mdi-menu-up"></i> {{tharwaAIObject.percentage + '% ($' + (tharwaAIObject.differenceAmount | number) + ')'}} </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style="width: 100%; height: 1px; background-color: #80808045; margin: 15px 0; padding: 0 5px;"></div>

            <table style="width: 100%;">
              <tbody>
                <tr>
                  <td style="width: 66.33%; text-align: center;">
                    <div class="d-flex">
                    <div style="border-radius: 50%; background-color: #00000000; width: 35px; 
                        border: 1px solid rgb(134 134 134 / 67%);height: 35px; text-align: center;">
                      <img src="assets/images/logo-sm-dark.png" alt="" height="20px" width="20px"
                        style="margin-top: 7px; ">
                    </div>
                    <p style="font-size: 12px; padding-right: 10px; margin: auto 0; padding: 5px 10px;">Tharwa Fund</p>
                  </div>
                  </td>                  
                  <td style="width: 33.3333%; text-align: end;">
                    <span *ngIf="tharwaIndexObject.percentage < 0" class="badge bg-danger-subtle text-danger font-size-11">
                      <i class="mdi mdi-menu-down"></i> {{tharwaIndexObject.percentage + '% ($' + (tharwaIndexObject.differenceAmount | number) + ')'}} </span>
                    <span *ngIf="tharwaIndexObject.percentage >= 0" class="badge bg-success-subtle text-success font-size-11">
                      <i class="mdi mdi-menu-up"></i> {{tharwaIndexObject.percentage + '% ($' + (tharwaIndexObject.differenceAmount | number) + ')'}} </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style="width: 100%; height: 1px; background-color: #80808045; margin: 15px 0; padding: 0 5px;"></div>

            <table style="width: 100%;">
              <tbody>
                <tr>
                  <td style="width: 66.33%; text-align: center;">
                    <div class="d-flex">
                    <div style="border-radius: 50%; background-color: #00000000; width: 35px; 
                      border: 1px solid rgb(134 134 134 / 67%);height: 35px; text-align: center;">
                      <div
                        style="margin-top: 1px; font-family: 'Times New Roman', Times, serif; font-weight: bold; font-size: 20px;">
                        <span>I</span>
                      </div>
                    </div>
                    <p style="font-size: 12px; padding-right: 10px; margin: auto 0; padding: 5px 10px;">Indicies</p>
                  </div>
                  </td>
                  <td style="width: 33.3333%; text-align: end;">
                    <span *ngIf="indexFundObject.percentage < 0" class="badge bg-danger-subtle text-danger font-size-11">
                      <i class="mdi mdi-menu-down"></i> {{indexFundObject.percentage + '% ($' + (indexFundObject.differenceAmount | number) + ')'}}</span>
                    <span *ngIf="indexFundObject.percentage >= 0" class="badge bg-success-subtle text-success font-size-11">
                      <i class="mdi mdi-menu-up"></i> {{indexFundObject.percentage + '% ($' + (indexFundObject.differenceAmount | number) + ')'}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style="width: 100%; height: 1px; background-color: #80808045; margin: 15px 0; padding: 0 5px;"></div>

            <table style="width: 100%;">
              <tbody>
                <tr>
                  <td style="width: 66.33%; text-align: center;">
                    <div class="d-flex">
                    <div style="border-radius: 50%; background-color: #00000000; width: 35px; 
                    border: 1px solid rgb(134 134 134 / 67%);height: 35px; text-align: center;">
                      <div
                        style="margin-top: 1px; font-family: 'Times New Roman', Times, serif; font-weight: bold; font-size: 20px;">
                        <span>B</span>
                      </div>
                    </div>
                    <p style="font-size: 12px; padding-right: 10px; margin: auto 0; padding: 5px 10px;">Bonds</p>
                  </div>
                  </td>
                  <td style="width: 33.3333%; text-align: end;">
                    <span *ngIf="bondsObject.percentage < 0" class="badge bg-danger-subtle text-danger font-size-11">
                      <i class="mdi mdi-menu-down"></i> {{bondsObject.percentage + '% ($' + (bondsObject.differenceAmount | number) + ')'}} </span>
                    <span *ngIf="bondsObject.percentage >= 0" class="badge bg-success-subtle text-success font-size-11">
                      <i class="mdi mdi-menu-up"></i> {{bondsObject.percentage + '% ($' + (bondsObject.differenceAmount | number) + ')'}} </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style="width: 100%; height: 1px; background-color: #80808045; margin: 15px 0; padding: 0 5px;"></div>

            <table style="width: 100%;">
              <tbody>
                <tr>
                  <td style="width: 66.33%; text-align: center;">
                    <div class="d-flex">
                    <div style="border-radius: 50%; background-color: #00000000; width: 35px; 
                  border: 1px solid rgb(134 134 134 / 67%);height: 35px; text-align: center;">
                      <div
                        style="margin-top: 1px; font-family: 'Times New Roman', Times, serif; font-weight: bold; font-size: 20px;">
                        <span>$</span>
                      </div>
                    </div>
                    <p style="font-size: 12px; padding-right: 10px; margin: auto 0; padding: 5px 10px;">Cash</p>
                  </div>
                  </td>
                  <td style="width: 33.3333%; text-align: end;">
                    <h4 class="mb-0" style="font-size: 14px; padding-right: 4px;">${{cashAmount | number}}</h4>
                  </td>
                </tr>
              </tbody>
            </table>


          </div>
          <!-- <div class="float-end d-none d-md-inline-block">
                <div class="btn-group mb-2">
                  <button type="button" class="btn btn-sm btn-light">Today</button>
                  <button type="button" class="btn btn-sm btn-light active">Weekly</button>
                  <button type="button" class="btn btn-sm btn-light">Monthly</button>
                </div>
              </div> -->
        </div>
      </div>
    </div>
    <div class="col-xl-8">
      <div class="card">
        <div class="card-body" style="padding-bottom: 6px;">
          <h4 class="card-title">Asset Distribution</h4>
          <div style="display: block; height: 350px;">
            <canvas style="margin: auto;" baseChart [data]="doughnutChartData" type="doughnut" [options]="doughnutChartOptions">
            </canvas>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Monthly Performance</h4>
          <div style="display: block; height: 350px; padding: 30px 20px;">
            <canvas baseChart [data]="barChartData" type="bar" [options]="barChartOptions">
            </canvas>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>