import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Dashboard',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 2,
        label: 'Transactoions',
        icon: 'ri-exchange-dollar-line',
        link: '/transactions'
    },
    // {
    //     id: 3,
    //     label: 'News',
    //     icon: 'ri-lightbulb-flash-line',
    //     link: '/News'
    // } 
];

