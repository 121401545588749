<div class="container-fluid">
  <div *ngIf="!newsDetail">
    <!-- start page title -->
    <app-pagetitle title="News"></app-pagetitle>
    <!-- end page title -->

    <div class="row">
      <div class="col-md-6 col-xl-4">
        <!-- Simple card -->
        <div class="card" style="min-height: 150px;">
          <img class="card-img-top img-fluid" src="assets/images/small/img-1.jpg" alt="Card image cap">
          <div class="card-body">
            <h4 class="card-title mt-0">Tharwatech Web App</h4>
            <p class="card-text">Tharwatech releases new platform for users</p>
            <div style="width: 100%; text-align: center;">
              <a href="javascript: void(0);" class="btn btn-primary" (click)="newsDetail = true">Read More</a>
            </div>
          </div>
        </div>
      </div>

   
    </div>
  </div>

  <div *ngIf="newsDetail" style="position: relative;">
    
      <div style="width: 100%; height: 50px;">
        <a href="#" (click)="newsDetail = false"><h6><strong>{{'< News'}}</strong></h6></a>
      </div>
    
    <div class="row">
      <div class="col-md-6">
        <div style="width: 100%;"></div>
        <img style="object-fit: contain; width: 100%; height: 100%;" src="assets/images/small/img-1.jpg" alt="Card image cap">        
      </div>
      <div class="col-md-6">
        <div style="width: 100%; padding: 50px;">

          <h4>News Title stating the following on the</h4>
          <p class="card-text" style="padding-top: 20px;">Date Published: 3 October 2024</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card-title-desc" style="padding: 30px 20px; ">
        
          
<h3><strong>1. What is the Stock Market?</strong></h3>
<p>The stock market is a collection of exchanges where shares of publicly traded companies are bought and sold. It acts as a marketplace for investors to purchase ownership in companies through stock shares. In essence, when you buy a share, you are buying a small piece of that company, entitling you to a fraction of its earnings and assets.</p>
<h4><strong>1.1. Stock Exchanges</strong></h4>
<p>The stock market is not a single entity but is made up of multiple exchanges, with the two most prominent being:</p>
<ul>
    <li><strong>New York Stock Exchange (NYSE)</strong>: The largest stock exchange in the world, it lists some of the most valuable companies globally, such as Apple and Microsoft.</li>
    <li><strong>Nasdaq</strong>: Known for its technology listings, it includes giants like Google (Alphabet) and Facebook (Meta).</li>
</ul>
<p>Other global exchanges include the London Stock Exchange, Tokyo Stock Exchange, and Shanghai Stock Exchange.</p>
<h3><strong>2. Types of Stocks</strong></h3>
<p>Stocks are broadly classified into two categories: <strong>common stocks</strong> and <strong>preferred stocks</strong>.</p>
<h4><strong>2.1. Common Stocks</strong></h4>
<p>Common stockholders have voting rights in the company and may receive dividends, which are portions of the company&rsquo;s profits. However, in the event of liquidation, common shareholders are the last to be paid, after creditors and preferred shareholders.</p>
<h4><strong>2.2. Preferred Stocks</strong></h4>
<p>Preferred stockholders have a higher claim on assets and earnings than common shareholders. They typically receive dividends before common shareholders and do not usually have voting rights. Preferred stocks are often seen as a more stable investment but with less potential for high growth compared to common stocks.</p>
<h3><strong>3. How the Stock Market Works</strong></h3>
<h4><strong>3.1. Initial Public Offering (IPO)</strong></h4>
<p>When a private company decides to go public, it issues an <strong>Initial Public Offering (IPO)</strong>, allowing investors to buy shares of the company for the first time. This is a significant event as it marks the transition of a company from private ownership to public trading. The funds raised during an IPO can be used for various purposes, such as expansion, research, or debt repayment.</p>
<h4><strong>3.2. Secondary Market Trading</strong></h4>
<p>Once shares are issued through an IPO, they can be traded in the <strong>secondary market</strong>. Here, buyers and sellers trade shares without involving the company. Stock prices fluctuate based on supply and demand, as well as other market factors such as earnings reports, economic data, and global events.</p>
<h3><strong>4. Factors Influencing Stock Prices</strong></h3>
<p>Several factors affect the rise and fall of stock prices, making the stock market a complex environment. Some of the most influential factors include:</p>
<h4><strong>4.1. Company Performance</strong></h4>
<p>One of the most significant factors influencing stock prices is the financial health of the company. Strong earnings reports, new product launches, or successful business expansions can boost stock prices. Conversely, poor earnings, management issues, or failed products can negatively affect prices.</p>
<h4><strong>4.2. Economic Indicators</strong></h4>
<p>The broader economic environment plays a critical role in stock market performance. Indicators such as GDP growth, inflation rates, and unemployment figures provide insights into the health of an economy. When the economy is growing, stock markets tend to rise as companies perform well. In contrast, during a recession, stock prices often fall due to declining corporate profits and investor confidence.</p>
<h4><strong>4.3. Interest Rates</strong></h4>
<p>Interest rates set by central banks, such as the Federal Reserve in the United States, directly affect stock prices. When interest rates are low, borrowing is cheaper, encouraging companies to invest and grow. Low rates also make bonds and other fixed-income investments less attractive, pushing investors toward stocks. On the other hand, high-interest rates can slow down business growth and reduce stock valuations.</p>
<h4><strong>4.4. Market Sentiment</strong></h4>
<p>Market sentiment reflects the general mood of investors and traders. Positive sentiment, driven by optimism about economic growth or specific industries, can push stock prices higher. Negative sentiment, often triggered by fear of economic downturns or geopolitical tensions, can lead to stock price declines. Market sentiment can sometimes overshadow fundamental factors, creating market volatility.</p>
<h3><strong>5. Stock Market Strategies</strong></h3>
<p>Investing in the stock market requires a strategy tailored to one&rsquo;s financial goals, risk tolerance, and investment horizon. Some of the most common stock market strategies include:</p>
<h4><strong>5.1. Buy and Hold</strong></h4>
<p>This strategy involves purchasing stocks and holding onto them for an extended period, regardless of short-term market fluctuations. Investors using this approach believe that, over time, stock prices will rise, and they will benefit from long-term capital appreciation. This is the preferred method for investors with a long-term outlook, such as those saving for retirement.</p>
<h4><strong>5.2. Value Investing</strong></h4>
<p>Popularized by legendary investors like Warren Buffett, <strong>value investing</strong> involves looking for undervalued stocks that are trading for less than their intrinsic value. These stocks may be temporarily out of favor with the market, but value investors believe they will eventually recover and provide substantial returns. The key to value investing is thorough analysis and patience.</p>
<h4><strong>5.3. Growth Investing</strong></h4>
<p><strong>Growth investors</strong> focus on companies with strong potential for future growth, even if they are currently overvalued. These companies are often in industries like technology or biotechnology, where innovation drives significant profit potential. Growth investing involves a higher level of risk, as these companies may not always meet their high expectations.</p>
<h4><strong>5.4. Dividend Investing</strong></h4>
<p>Investors seeking a steady income stream often turn to <strong>dividend investing</strong>. Companies that pay dividends regularly share a portion of their profits with shareholders. Dividend stocks tend to be more stable and are often found in industries like utilities or consumer goods. This strategy is popular among retirees or those looking for a conservative approach to investing.</p>
        </div>
      </div>
    </div>

  </div>
</div>