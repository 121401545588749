import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { latLng, tileLayer } from 'leaflet';


import { ChartType, Stat, Chat, Transaction } from './dashboard.model';

import { statData, revenueChart, salesAnalytics, sparklineEarning, sparklineMonthly, chatData, transactions, spark3Chart, spark2Chart, spark1Chart } from './data';
import { TharwaService } from '../tharwa/tharwa.service';
import { ChartConfiguration, ChartOptions } from 'chart.js';

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})

/**
 * Dashboard Component
 */
export class DashboardComponent implements OnInit {
  term: any;
  chatData: Chat[];
  transactions: Transaction[];
  statData: Stat[];
  cashAmount: number = 0;
  public doughnutChartLabels: string[] = ['Tharwa AI', 'Tharwa Fund', 'Indicies', 'Cash', 'Bonds'];

  public doughnutChartData: ChartConfiguration<'doughnut'>['data'] = {
    labels: this.doughnutChartLabels,
    datasets: [{
      data: [30, 20, 30, 10, 10],
      backgroundColor: [
        '#EDC263', // Yellow
        '#AF7F12', // Gold
        '#EE3D3D', // Red
        '#32D040', // Green
        '#2688DD', // Blue
      ],
      // borderColor: [
      //   '#EDC263', // Yellow
      //   '#AF7F12', // Gold
      //   '#EE3D3D', // Red
      //   '#32D040', // Green
      //   '#2688DD', // Blue
      // ],
      borderWidth: 0,
      weight: 4,
      hoverOffset: 20

    }],
  };
  public doughnutChartOptions: ChartOptions<'doughnut'> = {
    responsive: true,
    cutout: '75%',
    plugins: {
      legend: {
        position: 'right',
        labels: {
          // color: '#FFFFFF',
          font: {
            size: 11,
          },
          boxWidth: 10,
          boxHeight: 10,
          usePointStyle: true,
          pointStyle: 'circle',
        }
      },
      tooltip: {
        enabled: true // Disable the tooltip if you want the same look as the image
        
      }
    }
  };
  public lineChartData: any;

  public lineChartOptions: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,    
    scales: {
      y: {
        beginAtZero: false,
        ticks: {
          callback: function (value) {
            value = parseFloat(value.toString());
            if (Math.abs(value) >= 1.0e9) {
              return (value / 1.0e9).toFixed(1) + "B"; // Billions
            } else if (Math.abs(value) >= 1.0e6) {
              return (value / 1.0e6).toFixed(1) + "M"; // Millions
            } else if (Math.abs(value) >= 1.0e3) {
              return (value / 1.0e3).toFixed(1) + "K"; // Thousands
            }
            return value.toLocaleString(); // Display as numbers with commas
          },
          color: "#aaaaaa", // Customize the color of the Y-axis labels
        },
        grid: {
          color: "#a7a7a7", // Customize the color of the grid lines
          display: true,
        },        
      },
      x: {
        ticks: {
          color: "#aaaaaa", // Customize the color of the X-axis labels
        },
        grid: {
          display: false, // Remove grid lines for the X-axis
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
  };
  constructor(
    public formBuilder: UntypedFormBuilder,
    private tharwaService: TharwaService
  ) {}

  // bread crumb items
  breadCrumbItems: Array<{}>;

  revenueChart: ChartType;
  salesAnalytics: ChartType;
  sparklineEarning: ChartType;
  sparklineMonthly: ChartType;
  spark1: any;
  spark2: any;
  spark3: any;

  // Form submit
  chatSubmit: boolean;
  userKey: string;
  formData: UntypedFormGroup;
  userInvestments?: any;

  percentageDifference: number = 0;
  amountDifference: number = 0;

  //Distribution
  tharwaAIObject: any;
  tharwaIndexObject: any;
  indexFundObject: any;
  cryptoObject: any;
  bondsObject: any;
  mtdValue: number = 0;
  wtdValue: number = 0;

  assets: any;
  portfolioFilter: string = 'month';

  userLedger?: any;
  userTotalDepositWithdrawl: number = 0;
  totalProfits: number = 0;

  // Chart Data
  userTimePerformance: any[] = [];
  categories: string[] = [];
  values: number[] = [];

  public barChartData: any;
  public barChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value + '%'; // Display the percentage sign
          },
          color: '#aaaaaa', // Customize the color of the Y-axis labels
        },
        grid: {

          color: '#a7a7a777', // Customize the color of the grid lines
        }
      },
      x: {
        ticks: {
          // Adjusts the width of the bars
          // barPercentage: 0.5, // Default is 0.9
          // categoryPercentage: 0.5, // Default is 0.8
          color: '#aaaaaa', // Customize the color of the X-axis labels
        },
        grid: {
          display: false, // Remove grid lines for the X-axis
        }
      }
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      }
    }
  };

  

  options = {
    layers: [
      tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 18,
        attribution: "...",
      }),
    ],
    zoom: 6,
    center: latLng(46.879966, -121.726909),
  };

  ngOnInit(): void {
    let userKey = localStorage.getItem("userKey");
    if (userKey) {
      this.userKey = userKey;
      this.breadCrumbItems = [
        { label: "Tharwatech" },
        { label: "Dashboard", active: true },
      ];
      this.formData = this.formBuilder.group({
        message: ["", [Validators.required]],
      });
      this._fetchData();
      this.fetchUserPortfolio();
      this.fetchMonthlyPerformance();
    }
  }

  private _fetchData() {
    this.revenueChart = revenueChart;
    this.salesAnalytics = salesAnalytics;
    this.sparklineEarning = sparklineEarning;
    this.sparklineMonthly = sparklineMonthly;
    this.chatData = chatData;
    this.transactions = transactions;
    this.spark1 = spark1Chart;
    this.spark2 = spark2Chart;
    this.spark3 = spark3Chart;
  }

  /**
   * Returns form
   */
  get form() {
    return this.formData.controls;
  }

  /**
   * Save the message in chat
   */
  messageSave() {
    const message = this.formData.get("message").value;
    const currentDate = new Date();
    if (this.formData.valid && message) {
      // Message Push in Chat
      this.chatData.push({
        align: "right",
        name: "Ricky Clark",
        message,
        time: currentDate.getHours() + ":" + currentDate.getMinutes(),
      });

      // Set Form Data Reset
      this.formData = this.formBuilder.group({
        message: null,
      });
    }

    this.chatSubmit = true;
  }
  monthlyPerformanceData: any;

  async fetchMonthlyPerformance() {
    let userTimePerformance: any = await this.tharwaService.fetchTimePerformance(this.userKey, 'All').toPromise();
    if (userTimePerformance?.data?.success) {
      try {
      let performanceData = JSON.parse(JSON.stringify(userTimePerformance.data.data));
      if(performanceData.length > 0){
      let currentDate = new Date();
      let months = [
        this.getShorterMonth(currentDate.getMonth() - 5), 
        this.getShorterMonth(currentDate.getMonth() - 4), 
        this.getShorterMonth(currentDate.getMonth() - 3), 
        this.getShorterMonth(currentDate.getMonth() - 2),
        this.getShorterMonth(currentDate.getMonth() - 1),
        this.getShorterMonth(currentDate.getMonth())
      ]
      let monthValues = [
        this.calculateMonthPerformance((currentDate.getMonth() - 5), performanceData),
        -3,
        this.calculateMonthPerformance((currentDate.getMonth() - 3), performanceData),
        this.calculateMonthPerformance((currentDate.getMonth() - 2), performanceData),
        this.calculateMonthPerformance((currentDate.getMonth() - 1), performanceData),
        this.calculateMonthPerformance(currentDate.getMonth(), performanceData),
      ];
      this.monthlyPerformanceData = { months: months, monthValues: monthValues };
      this.barChartData = {
        labels: months,
        datasets: [{
          data: monthValues, // The data points
          backgroundColor: (context: any) => {
            const value = context.dataset.data[context.dataIndex];
            return parseFloat(value?.toString() ?? '0') > 0 ? '#0EBC1D' : '#E6181B'; // Green for positive, Red for negative
          },
          borderColor: 'rgba(0, 0, 0, 0)',
          borderWidth: 1,
          maxBarThickness: 20,
          borderRadius: 4
    
        }]
      };
    } else {
      let currentDate = new Date();
      let months = [
        this.getShorterMonth(currentDate.getMonth() - 5), 
        this.getShorterMonth(currentDate.getMonth() - 4), 
        this.getShorterMonth(currentDate.getMonth() - 3), 
        this.getShorterMonth(currentDate.getMonth() - 2),
        this.getShorterMonth(currentDate.getMonth() - 1),
        this.getShorterMonth(currentDate.getMonth())
      ]
      let monthValues = [
        1,
        -3,
        3,
        2,
        4,
        0,
      ];
      this.monthlyPerformanceData = { months: months, monthValues: monthValues };
      this.barChartData = {
        labels: months,
        datasets: [{
          data: monthValues, // The data points
          backgroundColor: (context: any) => {
            const value = context.dataset.data[context.dataIndex];
            return parseFloat(value?.toString() ?? '0') > 0 ? '#0EBC1D' : '#E6181B'; // Green for positive, Red for negative
          },
          borderColor: 'rgba(0, 0, 0, 0)',
          borderWidth: 1,
          maxBarThickness: 20,
          borderRadius: 4
    
        }]
      };
    }
    } catch(err: any){
      
    }
    }
  }

  calculateMonthPerformance(month: number, data: any){
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    if(month < 0) {
      year = year - 1;
      month = (12 + month);
    }
    let firstOfMonth = new Date(year, month, 2);
    let endOfMonth = new Date(year, month, 0);
    let firstOfMonthUnix = Number(firstOfMonth.getTime().toFixed(0));
    let endOfMonthUnix = Number((new Date(year, month, endOfMonth.getDate())).getTime().toFixed(0));
    let thisMonthPerformance = data.filter((p:any) => (Number(p.Category.toFixed(0)) >= firstOfMonthUnix && Number(p.Category.toFixed(0)) <= endOfMonthUnix));
    thisMonthPerformance = thisMonthPerformance.sort((n1: any, n2: any) => Number(n1.Category) - Number(n2.Category));
    
    if(thisMonthPerformance.length > 1){
      let percentage = Number((((thisMonthPerformance[(thisMonthPerformance.length - 1)].value - thisMonthPerformance[0].value) / thisMonthPerformance[0].value)*100).toFixed(0));
      return percentage;
    } 
    return this.percentageDifference;
  }

  async fetchUserLedger(userKey: string){
    let ledgerResponse: any = await this.tharwaService.fetchUserLedger(this.userKey).toPromise();
    if (ledgerResponse?.data?.success) {
      this.userLedger = ledgerResponse.data.data;
      this.calculateUserLedger();
      let currentDate = new Date();
      let statData = [
        {
            icon: 'ri-stack-line',
            title: 'Total Profits',
            value: '' + this.totalProfits
        }, 
        {
          icon: 'ri-calendar-2-line',
          title: 'WTD',
          value: '' + this.wtdValue
        },
        {
            icon: 'ri-calendar-2-line',
            title: 'MTD - ' + this.getFullMonth((new Date()).getMonth()),
            value: '' + this.mtdValue
        }
    ];
    this.statData = statData;
      this.fetchingChart = false;
    }
  }

  calculateUserLedger() {
    for(let i = 0; i < this.userLedger?.credits?.length; i++){
      let credit = this.userLedger?.credits[i];
      // this.tableData.push({id: (i+1), amount: credit.amount, date: this.getShortDateFromUnix(credit.createdDateUnix) });
      this.userTotalDepositWithdrawl = Number(this.userTotalDepositWithdrawl) + Number(credit.amount);
    }
    for(let i = 0; i < this.userLedger?.debits?.length; i++){
      let debit = this.userLedger?.debits[i];
      // this.tableData2.push({id: (i+1), amount: debit.amount, date: this.getShortDateFromUnix(debit.createdDateUnix) });
      this.userTotalDepositWithdrawl = Number(this.userTotalDepositWithdrawl) + Number(debit.amount);
    }


    this.totalProfits = Number(this.userInvestments.currentBalance) - Number(this.userTotalDepositWithdrawl);

  }

  performanceData: any[] = [];
  fetchingChart: boolean = true;
  async fetchTimePerformance() {
    this.categories = [];
    this.values = [];
    this.userTimePerformance = [];
    let userTimePerformance: any = await this.tharwaService.fetchTimePerformance(this.userKey, this.portfolioFilter).toPromise();
    if (userTimePerformance?.data?.success) {
      this.performanceData = JSON.parse(
        JSON.stringify(userTimePerformance.data.data)
      );
      let finalData: any[] = userTimePerformance.data.data;
      finalData = finalData.reverse();
      this.performanceData = this.performanceData.reverse();

      if (finalData.length > 20) {
        for (let i = finalData.length - 12; i < finalData.length; i++) {
          let point = finalData[i];
          this.categories.push(this.getShorterDateFromUnix(point.Category));
          this.values.push(Number(point.value.toFixed(0)));
          this.userTimePerformance.push({
            category: this.getShorterDateFromUnix(point.Category),
            value: Number(point.value),
          });
        }
      } else {
        for (let i = 0; i < finalData.length; i++) {
          let point = finalData[i];
          this.categories.push(this.getShorterDateFromUnix(point.Category));
          this.values.push(Number(point.value.toFixed(0)));
          this.userTimePerformance.push({
            category: this.getShorterDateFromUnix(point.Category),
            value: Number(point.value),
          });
        }
      }

      let currentDate = new Date();
      let firstOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      let firstOfMonthUnix = Number(firstOfMonth.getTime().toFixed(0));

      let thisMonthPerformance = this.performanceData.filter(
        (p: any) => Number(p.Category.toFixed(0)) >= firstOfMonthUnix
      );
      thisMonthPerformance = thisMonthPerformance.sort(
        (n1: any, n2: any) => Number(n1.Category) - Number(n2.Category)
      );

      if (thisMonthPerformance.length > 1) {
        this.mtdValue = Number(
          (
            thisMonthPerformance[thisMonthPerformance.length - 1].value -
            thisMonthPerformance[0].value
          ).toFixed(0)
        );
      } else {
        if (thisMonthPerformance.length == 1) {
          this.mtdValue = this.amountDifference;
        } else {
          this.mtdValue = 0;
        }
      }
      const currentDay = currentDate.getDay();
      const offsetToLastSunday = (currentDay + 7) % 7;
      const lastSundayDate = new Date(currentDate);
      lastSundayDate.setDate(currentDate.getDate() - offsetToLastSunday);
      let lastSundayUnix =  Number(lastSundayDate.getTime().toFixed(0));

      let thisWeekPerformance = this.performanceData.filter(
        (p: any) => Number(p.Category.toFixed(0)) >= lastSundayUnix
      );
      thisWeekPerformance = thisWeekPerformance.sort(
        (n1: any, n2: any) => Number(n1.Category) - Number(n2.Category)
      );
      

      if (thisWeekPerformance.length > 1) {
        this.wtdValue = Number(
          (
            thisWeekPerformance[thisWeekPerformance.length - 1].value -
            thisWeekPerformance[0].value
          ).toFixed(0)
        );
      } else {
        if (thisWeekPerformance.length == 1) {
          this.wtdValue = this.amountDifference;
        } else {
          this.wtdValue = 0;
        }
      }

      this.lineChartData = {
        labels: this.categories,
        datasets: [{
          data: this.values,
          borderColor: '#28a745',
          backgroundColor: 'rgba(0, 0, 0, 0)', // Remove background shade
          fill: false, // Disable fill
          tension: 0.4,
          pointRadius: 3,
          pointBorderColor: 'rgba(40, 167, 69)',
          pointBackgroundColor: 'rgba(255, 255, 255, 100)',
          pointHoverRadius: 7,
          borderWidth: 3, // Increase the line thickness
        }]
      };

      this.lineChartOptions = {
        responsive: true,
        maintainAspectRatio: false,    
        scales: {
          y: {
            beginAtZero: false,
            ticks: {
              callback: function (value) {
                value = parseFloat(value.toString());
                if (Math.abs(value) >= 1.0e9) {
                  return (value / 1.0e9).toFixed(1) + "B"; // Billions
                } else if (Math.abs(value) >= 1.0e6) {
                  return (value / 1.0e6).toFixed(1) + "M"; // Millions
                } else if (Math.abs(value) >= 1.0e3) {
                  return (value / 1.0e3).toFixed(1) + "K"; // Thousands
                }
                return value.toLocaleString(); // Display as numbers with commas
              },
              color: "#aaaaaa", // Customize the color of the Y-axis labels
            },
            grid: {
              color: "#a7a7a777", // Customize the color of the grid lines
              display: true,
            },        
          },
          x: {
            ticks: {
              color: "#aaaaaa", // Customize the color of the X-axis labels
            },
            grid: {
              display: false, // Remove grid lines for the X-axis
            },
          },
        },
        plugins: {
          legend: {
            display: false, // Hide the legend
          },
        },
      };
      let ledger = await this.fetchUserLedger(this.userKey);
      

    }
  }

  async fetchUserPortfolio() {
    let userInvestmentResponse: any = await this.tharwaService
      .fetchUserPortfolio(this.userKey)
      .toPromise();
    if (userInvestmentResponse?.data?.success) {
      this.userInvestments = userInvestmentResponse.data.data;
      this.userInvestments.currentBalance = Number(
        this.userInvestments.currentBalance.toFixed(0)
      );

      if (
        Number(this.userInvestments.currentBalance) >=
        Number(this.userInvestments.previousDayBalance)
      ) {
        this.percentageDifference = Number(
          (
            ((Number(this.userInvestments.currentBalance) -
              Number(this.userInvestments.previousDayBalance)) /
              Number(this.userInvestments.previousDayBalance)) *
            100
          ).toFixed(2)
        );
        this.amountDifference = Number(
          (
            Number(this.userInvestments.currentBalance) -
            Number(this.userInvestments.previousDayBalance)
          ).toFixed(0)
        );
      } else {
        this.percentageDifference = Number(
          (
            ((Number(this.userInvestments.previousDayBalance) -
              Number(this.userInvestments.currentBalance)) /
              Number(this.userInvestments.currentBalance)) *
            -100
          ).toFixed(2)
        );
        this.amountDifference = Number(
          (
            (Number(this.userInvestments.previousDayBalance) -
              Number(this.userInvestments.currentBalance)) *
            -1
          ).toFixed(0)
        );
      }

      this.tharwaAIObject = {
        percentage: this.userInvestments.currentDay.TharwaAI,
        amount: Number(
          (0.2 * Number(this.userInvestments.currentBalance)).toFixed(0)
        ),
        differenceAmount:
          Number(this.userInvestments.currentDay.TharwaAI) < 0
            ? -1 *
              Number(
                (
                  (this.userInvestments.currentDay.TharwaAI / 100) *
                  Number(this.userInvestments.currentBalance)
                ).toFixed(0)
              )
            : Number(
                (
                  (this.userInvestments.currentDay.TharwaAI / 100) *
                  Number(this.userInvestments.currentBalance)
                ).toFixed(0)
              ),
      };

      this.tharwaIndexObject = {
        percentage: this.userInvestments.currentDay.TharwaFund,
        amount: Number(
          (0.3 * Number(this.userInvestments.currentBalance)).toFixed(0)
        ),
        differenceAmount:
          Number(this.userInvestments.currentDay.TharwaFund) < 0
            ? -1 *
              Number(
                (
                  (this.userInvestments.currentDay.TharwaFund / 100) *
                  Number(this.userInvestments.currentBalance)
                ).toFixed(0)
              )
            : Number(
                (
                  (this.userInvestments.currentDay.TharwaFund / 100) *
                  Number(this.userInvestments.currentBalance)
                ).toFixed(0)
              ),
      };

      this.indexFundObject = {
        percentage: this.userInvestments.currentDay.IndexFunds,
        amount: Number(
          (0.3 * Number(this.userInvestments.currentBalance)).toFixed(0)
        ),
        differenceAmount:
          Number(this.userInvestments.currentDay.IndexFunds) < 0
            ? -1 *
              Number(
                (
                  (this.userInvestments.currentDay.IndexFunds / 100) *
                  Number(this.userInvestments.currentBalance)
                ).toFixed(0)
              )
            : Number(
                (
                  (this.userInvestments.currentDay.IndexFunds / 100) *
                  Number(this.userInvestments.currentBalance)
                ).toFixed(0)
              ),
      };

      this.cryptoObject = {
        percentage: this.userInvestments.currentDay.Crypto,
        amount: Number(
          (0.1 * Number(this.userInvestments.currentBalance)).toFixed(0)
        ),
        differenceAmount:
          Number(this.userInvestments.currentDay.Crypto) < 0
            ? -1 *
              Number(
                (
                  (this.userInvestments.currentDay.Crypto / 100) *
                  Number(this.userInvestments.currentBalance)
                ).toFixed(0)
              )
            : Number(
                (
                  (this.userInvestments.currentDay.Crypto / 100) *
                  Number(this.userInvestments.currentBalance)
                ).toFixed(0)
              ),
      };

      this.bondsObject = {
        percentage: this.userInvestments.currentDay.Bonds,
        amount: Number(
          (0.1 * Number(this.userInvestments.currentBalance)).toFixed(0)
        ),
        differenceAmount:
          Number(this.userInvestments.currentDay.Bonds) < 0
            ? -1 *
              Number(
                (
                  (this.userInvestments.currentDay.Bonds / 100) *
                  Number(this.userInvestments.currentBalance)
                ).toFixed(0)
              )
            : Number(
                (
                  (this.userInvestments.currentDay.Bonds / 100) *
                  Number(this.userInvestments.currentBalance)
                ).toFixed(0)
              ),
      };
      this.cashAmount = Number((this.userInvestments.currentBalance * 0.05).toFixed(0))
      
      await this.fetchTimePerformance();

      this.assets = {
        tharwaAIObject: this.tharwaAIObject,
        tharwaIndexObject: this.tharwaIndexObject,
        indexFundObject: this.indexFundObject,
        cryptoObject: this.cryptoObject,
        bondsObject: this.bondsObject,
        totalBalance: this.userInvestments.currentBalance,
      };
    }
  }

  
  getDateTimeUnix() {
    return Number(
      new Date().getTime().toFixed(0)
    );
  }


  getShortDateFromUnix(unixTime: number){
    let cD = new Date((unixTime));
    let month = cD.toLocaleString('default', { month: 'long' });
    return cD.getDate() + ' ' + month + ', ' + cD.getFullYear();
  }

  getShorterDateFromUnix(unixTime: number){
    let cD = new Date((unixTime));
    let month = cD.toLocaleString('default', { month: 'short' });
    return cD.getDate() + '-' + month;
  }


  getShorterMonth(month: number){
    let cD = new Date(2000, month, 1);
    let monthName = cD.toLocaleString('default', { month: 'short' });
    return monthName;
  }

    getFullMonth(month: number){
    let cD = new Date(2000, month, 1);
    let monthName = cD.toLocaleString('default', { month: 'long' });
    return monthName;
  }
}
