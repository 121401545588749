import { Component, OnInit } from '@angular/core';

import { EventService } from '../../core/services/event.service';

import { LAYOUT_VERTICAL, LAYOUT_HORIZONTAL } from './layouts.model';
import { TharwaService } from 'src/app/pages/tharwa/tharwa.service';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  fetching: boolean = true;
  // layout related config
  layoutType: string = LAYOUT_HORIZONTAL;
  userKey: string;
  constructor(private eventService: EventService, private tharwaService: TharwaService) { }

  ngOnInit() {
    // default settings
    document.body.setAttribute('data-bs-theme', 'dark');
    this.layoutType = LAYOUT_VERTICAL;
    // listen to event and change the layout, theme, etc
    this.eventService.subscribe('changeLayout', (layout) => {
      this.layoutType = layout;
    });
    this.userKey = localStorage.getItem('userKey');
    if(this.userKey)
      this.fetchUser();
  }

  async fetchUser() {
    let user: any = await this.tharwaService.fetchUser(this.userKey).toPromise();
    if (user?.data?.success) {
      let userData = user.data.data;
      localStorage.setItem('user', JSON.stringify(userData));
      this.fetching = false;
    }
  }

  /**
   * Check if the vertical layout is requested
   */
  isVerticalLayoutRequested() {
    return this.layoutType === LAYOUT_VERTICAL;
  }

  /**
   * Check if the horizontal layout is requested
   */
  isHorizontalLayoutRequested() {
    return this.layoutType === LAYOUT_HORIZONTAL;
  }
}
