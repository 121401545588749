<!-- <app-loader></app-loader> -->
<div class="container-fluid">
  <app-pagetitle title="Transactions"></app-pagetitle>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Deposits</h4>          
          <div class="table-responsive">
            <table class="table table-striped mb-0">
              <thead>
                <tr>
                  <th style="text-align: center;">ID</th>
                  <th style="text-align: center;">Amount</th>
                  <th style="text-align: center;">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let x of deposits">
                  <th scope="row" style="text-align: center;">{{x.id}}</th>
                  <td style="text-align: center;">${{x.amount | number}}</td>
                  <td style="text-align: center;">{{x.date}}</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Withdrawls</h4>          
          <div class="table-responsive">
            <table class="table table-striped mb-0">
              <thead>
                <tr>
                  <th style="text-align: center;">ID</th>
                  <th style="text-align: center;">Amount</th>
                  <th style="text-align: center;">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let x of withdrawls">
                  <th scope="row" style="text-align: center;">{{x.id}}</th>
                  <td style="text-align: center;">${{x.amount | number}}</td>
                  <td style="text-align: center;">{{x.date}}</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
