import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TharwaService {
  fbTharwaFunctions: string = 'https://us-central1-tharwa-228bd.cloudfunctions.net/';
  constructor(private http: HttpClient) { }

  fetchUser(userKey){
    return this.http.post(this.fbTharwaFunctions + 'fetchUser', {userKey: userKey});
  }

  fetchUserPortfolio(userKey: string){
    return this.http.post(this.fbTharwaFunctions + 'fetchUserInvestments', {userKey: userKey});
  }

  fetchTimePerformance(userKey: string, portfolioFilter: string) {
    return this.http.post(this.fbTharwaFunctions + 'fetchTimePerformance', {userKey: userKey, timeVal: portfolioFilter});
  }

  fetchUserLedger(userKey: string) {
    return this.http.post(this.fbTharwaFunctions + 'fetchUserLedger', {userKey: userKey});
  }

}
